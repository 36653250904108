import { DATEFORMAT } from './Constants';
import { format } from 'date-fns';
import ja from "date-fns/locale/ja";

const isInvalidDate = (date) => Number.isNaN(date.getTime());

export function DateFormat(date, _format = DATEFORMAT, locale = ja) {
  if (isInvalidDate(date)) { return ""; }
  return format(date, _format, {locale: locale});
}

export function checkContainsParentheses(str) {
  if (str.includes("(") || str.includes("（") || str.includes(")") || str.includes("）")) {
    return true;
  } else {
    return false;
  }
}

