import React from "react";
import { Button } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";

export function Error() {
  return (
    <div className="App">
      <div id="main" className="main">
        <h1 className="title">404 NOT FOUND</h1>
        <label htmlFor="button-menu-check" className="exclamation-image"></label>
        <div>お探しのページは見つかりませんでした。</div>
        <Button className="btn" variation="primary">
          <Link to={"/"}>競技会検索へ</Link>
        </Button>
      </div>
    </div>
  );
}
