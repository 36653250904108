import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useAuthenticator, Button } from '@aws-amplify/ui-react';
import axios from "axios";
import { API_URLS } from '../Constants';

export const VerificationCode = () => {
  const navigate = useNavigate();
  const [ code, setCode ] = useState("");
  const [ errorMessage, setErrorMessage ] = useState("");

  const verification = async () => {
    try {
      const attr = "email"
      const currentUser = await Auth.currentAuthenticatedUser();
      const jwtToken = currentUser.signInUserSession.getIdToken().getJwtToken();
      const accessToken = currentUser.signInUserSession.accessToken.jwtToken;
      const authrization = {Authorization: jwtToken};
      const data = {code: code, accessToken: accessToken};

      const response = await axios.post(
        API_URLS.VERIFY_ATTRIBUTE,
        data,
        {headers: authrization}
      );

      navigate("/update/user/done");
    } catch (error) {
      let message = "";
      switch (error.code) {
        case 'AliasExistsException':
          alert("メールアドレスが他のユーザーに登録されています。");
          break;
        case 'CodeMismatchException':
          message = "送信されたコードと一致しません。";
          break;
        case 'ExpiredCodeException':
          message = "コードの有効期限が切れています。";
          break;
        default:
          alert("更新に失敗しました。\nメールアドレス変更の手順から再操作をお願いします。");
      }

      setErrorMessage(message);
    }
  }

  const resendCode = () => {
    try {
      Auth.verifyCurrentUserAttribute("email");
    } catch (error) {
      alert("コードの再送信に失敗しました。");
    }
  }

  return (
    <div className="App">
      <div id="main" className="main">
        <h1 className="mb30">メールアドレス変更承認コード</h1>
        <div className="fz13 mb30">
          <div className="mb10">コード</div>
          <input type="text" className="input" value={code} onChange={(e) => setCode(e.target.value)} />
          <div className="alert">{errorMessage}</div>
        </div>
        <Button onClick={() => resendCode()} className="btn" variation="primary">コード再送信</Button>
        <Button onClick={() => verification()} className="btn" variation="primary">確認</Button>
      </div>
    </div>
  );
}
