import { React, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuthenticator, Button } from "@aws-amplify/ui-react";
import axios from "axios";
import Modal from 'react-modal';
import { format } from "date-fns";

import { API_URLS } from "../Constants";
import { DateFormat } from "../Utils";
import { Error } from './Error';

const uploadModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    height: '150px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 100,
  }
};

export const ContractDetail = () => {
  const navigate = useNavigate();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [isDownload, setIsDownload] = useState(false);
  const [competition, setCompetition] = useState({});
  const { user } = useAuthenticator((context) => [context.user]);
  const params = useParams();
  const [selectedFile, setselectedFile] = useState(null);
  const [uploadEnabled, setUploadEnabled] = useState(false);
  const [cancelEnabled, setCancelEnabled] = useState(true);

  const fetchData = async () => {
    // You can await here
    const jwtToken = user.signInUserSession.getIdToken().getJwtToken();
    const authrization = {Authorization: jwtToken};
    const result = await axios(
      API_URLS.CONTRACT_COMPETITIONS + "/" + params.competitionid,
      {headers: authrization}
    );
    setCompetition(result.data.result);
  }

  const closeUploadModal = () => {
    setUploadEnabled(false);
    setCancelEnabled(true);
    setselectedFile(null);
    setIsUploadModalOpen(false);
  }

  const download = async () => {
    const headers = {
      Authorization: user.signInUserSession.getIdToken().getJwtToken(),
      Accept: "application/octet-stream",
    };
    setIsDownload(true);
    const response = await axios.get(API_URLS.DOWNLOAD_ENTRIES + "/" + params.competitionid, {
      headers: headers,
      responseType: 'blob'
    })
    // JSON形式ではないのでdata.resultの形ではエラーになる
    const blob = new Blob([response.data], { type: 'application/octet-stream' })
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `dancesport_${format(new Date(), "yyyyMMdd")}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    setIsDownload(false);
  }
  const toggleCompetitionStatus = async (newStatus) => {
    const authrization = {
      Authorization: user.signInUserSession.getIdToken().getJwtToken(),
    };

    await axios.put(
      API_URLS.COMPETITIONS + "/" + params.competitionid,
      {...competition, status: newStatus},
      {
        headers: authrization,
      }
    );

    if (newStatus === "削除") {
      navigate('/delete/competition/done');
    } else if (newStatus === "公開") {
      navigate('/undelete/competition/done');
    }
  }

  const startUpload = async (e) => {
    setUploadEnabled(false);
    setCancelEnabled(false);

    const formData = new FormData();
    formData.append("data_upload", selectedFile);
    await axios.put(API_URLS.UPLOAD + "/" + params.competitionid, formData, {
      headers: {
        Authorization: user.signInUserSession.getIdToken().getJwtToken(),
        'Content-Type': 'multipart/form-data'
      }
    })
    fetchData();
    setselectedFile(null);
    setIsUploadModalOpen(false);
    setCancelEnabled(true);
  }

  useEffect(() => {
    fetchData();
  }, [user]);

  if (competition) {
    let name = competition.name;
    let buttonText = "削除";
    let modalText = competition.name + "を削除しますか";
    let newStatus = "削除";
  
    if (competition.status === "削除") {
      name = name + "（削除済み）";
      buttonText = "公開";
      modalText = competition.name + "を公開にしますか";
      newStatus = "公開";
    }
  
    return (
      <div className="App">
        <div className="main">
          <div className="comp-detail comp-detail-short">
            <div className="comp-detail-title color">{name}</div>
            <div className="comp-detail-content">
              <div className="flex mb16">
                <label
                  htmlFor="button-menu-check"
                  className="emoji-image comp-detail-date"
                ></label>
                <div>
                  <div className="bold mb10">開催日</div>
                  {DateFormat(new Date(competition.event_date))}
                </div>
              </div>
              <div className="flex mb16">
                <label
                  htmlFor="button-menu-check"
                  className="emoji-image comp-detail-calnder"
                ></label>
                <div>
                  <div className="bold mb10">エントリー期限</div>
                  {DateFormat(new Date(competition.entry_date))}
                </div>
              </div>
              <div className="flex mb16 detail-detail">
                <label
                  htmlFor="button-menu-check"
                  className="emoji-image comp-detail-info"
                ></label>
                <div>
                  <div className="bold mb10">詳細</div>
                  <a
                    href={competition.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {competition.url}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              className="btn"
              variation="primary"
              isDisabled={competition.status === "削除"}
              onClick={() => navigate("/update/competition/" + params.competitionid)}
            >
              競技会編集
            </Button>
            <Button
              className="btn"
              variation="primary"
              onClick={() => setIsUploadModalOpen(true)}
              isDisabled={competition.status === "削除"}
            >
              競技会一括更新
            </Button>
            <Button className="btn" variation="primary" isDisabled={isDownload} isLoading={isDownload} loadingText="Downloading..." onClick={async () => await download()} isDisabled={competition.status === "削除"}>
              エントリー状況ダウンロード
            </Button>
            <Button className="btn" variation="primary" onClick={() => setIsDeleteConfirmOpen(true)}>
              {buttonText}
            </Button>
            <Modal
              isOpen={isDeleteConfirmOpen}
              onRequestClose={() => setIsDeleteConfirmOpen(false)}
              ariaHideApp={false}
              style={{content: {height: '120px'}}}
              contentLabel="Confirmation Modal"
            >
              {modalText}
              <div className="modal-button">
                <div className="delete-confirm mr30" onClick={() => setIsDeleteConfirmOpen(false)}>いいえ</div>
                <div className="delete-confirm" onClick={async () => await toggleCompetitionStatus(newStatus)}>はい</div>
              </div>
            </Modal>
            <Modal
              isOpen={isUploadModalOpen}
              onRequestClose={closeUploadModal}
              style={uploadModalStyles}
              contentLabel="File Upload Modal"
            >
              <h2 className="mb10">競技会情報のExcelファイルを選択してください</h2>
              <div className="mb10">
                <input type="file" onChange={(event) => {
                  const fileTarget = event.target;
                  if (fileTarget.files.length > 0) {
                    setselectedFile(fileTarget.files[0]);
                    setUploadEnabled(true);
                  } else {
                    setselectedFile(null);
                    setUploadEnabled(false);
                  }
                }} />
              </div>
              <div className="modal-button">
                {cancelEnabled === true ? <div className="delete-confirm mr30" onClick={closeUploadModal}>キャンセル</div> : <div style={{"color": "gray", "padding": "5px", "fontWeight": "bold"}}>キャンセル</div>}
                {uploadEnabled === true ?
                  <div className="delete-confirm" onClick={async () => {await startUpload()}}>登録する</div> :
                  <div style={{"color": "gray", "padding": "5px", "fontWeight": "bold"}}>登録する</div>}
              </div>
            </Modal>

          </div>
        </div>
      </div>
    );
  } else {
    return(
    <Error />
    );  
  }
}
