import { useState, useEffect } from "react"; //Reactを読み込んでいる
import { useNavigate } from "react-router-dom"; //　追加　Linkタブを読み込む
import { useAuthenticator, Button } from "@aws-amplify/ui-react";
import { data } from "../atom.js"
import { useRecoilValue } from 'recoil';
import axios from "axios";

import { API_URLS } from "../Constants";
import { CompetitionUl } from "../components/Competition";

export const Mypage = () => {
  console.log("Mypage render");
  const [competitions, setCompetitions] = useState([]);
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const atoms = useRecoilValue(data);
  const fetchData = async () => {
    const resAthletes = await axios(API_URLS.ATHLETES);
    const competitionUUIDs = resAthletes.data.result
      .filter((ath) => ath.user === user.attributes.sub)
      .map((ath) => ath.competition);
    const resCompetitions = await axios(API_URLS.COMPETITIONS);
    setCompetitions(
      resCompetitions.data.result.filter(
        (com) => (competitionUUIDs.indexOf(com.uuid) !== -1) && (com.status === "公開")
      )
    );
  }
  useEffect(() => {
    if (user && competitions.length === 0) {
      fetchData();
    }
  }, [user]);

  const family_name = () => {
    if (atoms.family_name !== "default" && atoms.family_name !== undefined) {
      return atoms.family_name;
    } else if (!user.attributes.family_name) {
      return user.attributes.email;
    } else if (!user) {
      return "";
    }
    return user.attributes.family_name;
  }
  return (
    <div className="App">
        <div className="color welcome">
          <div className="welcome-text">
            <div className="mb10 fz18">ようこそ</div>
            <div className="flex">
              <div className="fz18">{family_name()}</div>
              <div className="fz14 san">さん</div>
            </div>
          </div>
        </div>
        <div id="main" className="pt100 main">
          <div className="mypage-button">
            <Button className="btn" variation="primary" onClick={() => navigate("/update/user")}>
              ユーザー情報編集
            </Button>
          </div>
          <div>
            <Button className="btn" variation="primary" onClick={() => navigate("/")}>
              競技会検索
            </Button>
          </div>
          <div className="mt30">出場登録済み競技会一覧</div>
          <CompetitionUl objs={competitions} />
        </div>
    </div>
  );
}
