import React from 'react';
import { Link } from "react-router-dom";
import { DateFormat } from "../Utils";

export function Competition(props) {
  const name = `${props.obj.name}${props.obj.status === "削除" ? "（削除済み）" : ""}`;

  return (
    <li className="tb-border comp">
      { props.isContractor === 1 ? (
        <Link to={"/contract/detail/" + props.obj.uuid}>
          <div className="comp-title mb16">
            <label
              htmlFor="emoji-image"
              className="emoji-image comp-detail-date"
            ></label>
            {name}
          </div>
          <p>開催日：{DateFormat(new Date(props.obj.event_date))}</p>
          <p>エントリー期限：{DateFormat(new Date(props.obj.entry_date))}</p>
        </Link>
      ) : (
        <Link to={"/detail/" + props.obj.uuid}>
          <div className="comp-title mb16">
            <label
              htmlFor="emoji-image"
              className="emoji-image comp-detail-date"
            ></label>
            {props.obj.name}
          </div>
          <p>開催日：{DateFormat(new Date(props.obj.event_date))}</p>
          <p>エントリー期限：{DateFormat(new Date(props.obj.entry_date))}</p>
        </Link>
      )}
    </li>
  );
}

export function CompetitionUl(props) {
  const MemoComponent = React.memo(Competition)
  return (
    <ul>
      {props.objs.map((obj, i) => (
        <MemoComponent obj={obj} isContractor={props.isContractor} key={i} />
      ))}
    </ul>
  );
}
