import React from 'react';
import { Auth } from 'aws-amplify';
import { useState } from "react";
import { Button, PasswordField } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

export const ChangePassword = () => {
  const navigate = useNavigate();
  const [ oldPassword, setOldPassword ] = useState("");
  const [ errorOldPassword, setErrorOldPassword ] = useState(false);
  const [ newPassword, setNewPassword ] = useState("");
  const [ errorNewPassword, setErrorNewPassword ] = useState(false);
  const [ confirmPassword, setConfirmPassword ] = useState("");
  const [ errorConfirmPassword, setErrorConfirmPassword ] = useState(false)

  const isValid = () => {
    let valid = true;
    if (oldPassword === "" || oldPassword === undefined) {
      setErrorOldPassword("現在のパスワードを入力してください。");
      valid = false
    } else if (errorOldPassword !== "") {
      setErrorOldPassword("");
    }
    if (newPassword === "" || newPassword === undefined) {
      setErrorNewPassword("新しいパスワードを入力してください。");
      valid = false;
    } else if (errorNewPassword !== "") {
      setErrorNewPassword("");
    }
    if (confirmPassword === "" || confirmPassword === undefined) {
      setErrorConfirmPassword("確認用のパスワードを入力してください。");
      valid = false;
    } else if (confirmPassword !== newPassword) {
      setErrorConfirmPassword("新しいパスワードと一致しません。");
      valid = false
    } else if (confirmPassword === newPassword) {
      setErrorConfirmPassword("");
    }

    return valid;
  }

  const change = async () => {
    if (!isValid()) {
      return
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await Auth.changePassword(user, oldPassword, newPassword);
      navigate("/change/password/done");
    } catch(error) {
      alert("入力情報が間違っています。");
    }
  }

  return (
    <div className="App">
      <div id="main" className="main">
        <h1 className="title">パスワード変更</h1>
        <div className="fz13 mb30">
          <div className="mb30">
            <PasswordField autoComplete="new-password" descriptiveText="" label="現在のパスワード" name="old-password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
            <div className="alert">{errorOldPassword}</div>
          </div>
          <div className="mb30">
            <PasswordField autoComplete="new-password" descriptiveText="" label="新しいパスワード" name="new-password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
            <div className="alert">{errorNewPassword}</div>
          </div>
          <div className="mb30">
            <PasswordField autoComplete="confirm-password" descriptiveText="" label="確認用パスワード" name="confirm-password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
            <div className="alert">{errorConfirmPassword}</div>
          </div>
          <Button onClick={() => {change()}} className="btn" variation="primary">パスワード変更</Button>
        </div>
      </div>
    </div>
  );
}
