import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { GoogleFormProvider, useGoogleForm } from 'react-google-forms-hooks'
import { useAuthenticator, Button } from '@aws-amplify/ui-react';
import form from '../scripts/form.json'

import ShortAnswerInput from "../components/ShortAnswerInput";
import LongAnswerInput from "../components/LongAnswerInput";

const Questions = (email) => {
  return (
    <div>
      {form.fields.map((field) => {
        const { id } = field;

        let questionInput = null;
        switch (field.type) {
          case "SHORT_ANSWER":
            questionInput = <ShortAnswerInput id={id} field={field.label} email={email !== undefined ? email.email : ""}/>;
            break;
          case "LONG_ANSWER":
            questionInput = <LongAnswerInput id={id} field={field.label} />;
            break;
          default:
            return null;
        }

        if (!questionInput) {
          return null;
        }

        return (
          <div className="mb30" key={id}>
            <div className="mb10">{field.label}<div className="mandatory">必須</div></div>
            {questionInput}
          </div>
        );
      })}
    </div>
  );
};

export function Contact() {
  const methods = useGoogleForm({ form });
  const navigate = useNavigate()
  const { user } = useAuthenticator((context) => [context.user]);
  const onSubmit = async (data) => {
    await methods.submitToGoogleForms(data);
    navigate('/contact/done');
  };
  return (
    <div className="App">
      <div id="main" className="main">
        <h1 className="title">お問い合わせ</h1>
        <div className="mb30">
          ご質問などは下記より必要事項をご記入しお問合せください。
        </div>
        <GoogleFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Questions email={user !== undefined ? user.attributes.email : undefined} />
            <div className="mb30"><Link to={"/privacy"} className="link">プライバシーポリシー</Link>の内容にご同意いただけましたら送信してください。</div>
            <Button className="btn" type="submit" variation="primary">
              お問い合わせを送信
            </Button>
          </form>
        </GoogleFormProvider>
      </div>
    </div>
  );
}
