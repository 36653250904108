import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuthenticator, Button } from '@aws-amplify/ui-react';

import { API_URLS } from '../Constants';
import { EntrySelect } from '../components/EntrySelect';
import "../App.css";
import axios from "axios";
import { Error } from './Error';

export function IsEmpty(data) {
  // まずイベントがなかったらエラー
  if (!data.event) {
    return true;
  }
  // 次はleaderとpartnerが両方undefinedだったらエラー
  // これでどちらか、または両方がセットされていたらOKになる
  if (!data.leader || !data.partner) {
    return true;
  }
  return false;
}

export function IsValid(athlete, data) {
  // leaderとpartnerが同じでない
  if (data.leader !== data.partner) { 
    //  どちらかが自分
    if (data.leader === athlete.PK || data.partner === athlete.PK) {
          return true;
    }
  }
  return false;
}

export async function Register(jwtToken, uuid, data) {
  const authrization = {Authorization: jwtToken};
  if (uuid !== "" && uuid !== undefined) {
    //const response = await axios.put(
    //  API_URLS.ENTRIES + "/" + uuid,
    //  data,
    //  {headers: authrization}
    //);
    //return response
  } else {
    const response = await axios.post(
      API_URLS.ENTRIES,
      data,
      {headers: authrization}
    );
    return response
  }
}
export async function Cancel(jwtToken, uuid) {
  const authrization = {Authorization: jwtToken};
  await axios.delete(
    API_URLS.ENTRIES + "/" + uuid,
    {headers: authrization}
  )
}
const getLeader = (athlete, entry) => {
  const value = entry !== undefined ? entry.leader : "";
  if (athlete.leader && !athlete.partner) {
    return value !== "" ? value : athlete.PK;
  } 
  return value;
}
const getPartner = (athlete, entry) => {
  const value = entry !== undefined ? entry.partner : "";
  if (!athlete.leader && athlete.partner) {
    return value !== "" ? value : athlete.PK;
  } 
  return value;
}
const getLeaders = (athlete, event, leaders) => {
  const dream = {PK: "抽選", nicname: "抽選"};
  let result = leaders;
  if (athlete.leader && athlete.partner) {
    if (event.is_dream) {
      result = [dream].concat(athlete);
    }
  } else if (athlete.leader) {
    result = [athlete];
  } else if (event.is_dream) {
    result = [dream];
  }
  return result;
}
const getPartners = (athlete, event, partners) => {
  const dream = {PK: "抽選", nicname: "抽選"};
  let result = partners;
  if (athlete.leader && athlete.partner) {
    if (event.is_dream) {
      result = [dream].concat(athlete);
    }
  } else if (athlete.partner) {
    result = [athlete];
  } else if (event.is_dream) {
    result = [dream];
  }
  return result;
}

export function Entry() {
  const navigate = useNavigate();
  const [ competition, setCompetition ] = useState([]);
  const [ events, setEvents ] = useState([]);
  const [ entries, setEntries ] = useState([]);
  const [ leaders, setLeaders ] = useState([]);
  const [ partners, setPartners ] = useState([]);
  const [ athlete, setAthlete ] = useState([]);
  const [ formIsDirty, setFormIsDirty ] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const params = useParams();
  async function fetchData() {
    const resCompetition = await axios(API_URLS.COMPETITIONS + "/" + params.competitionid);
    if (resCompetition.data.result.status !== "公開") {
      return;
    }
    setCompetition(resCompetition.data.result);

    if (resCompetition.data.result) {
      const resAthletes = await axios(API_URLS.ATHLETES);
      setLeaders(resAthletes.data.result.filter(athlete => athlete.competition === resCompetition.data.result.uuid && athlete.leader === true));
      setPartners(resAthletes.data.result.filter(athlete => athlete.competition === resCompetition.data.result.uuid && athlete.partner === true));
      const _athlete = resAthletes.data.result.filter(ath => ath.user === user.attributes.sub && ath.competition === params.competitionid)[0];
      setAthlete(_athlete);

      const resEvents = await axios(API_URLS.EVENTS);
      setEvents(resEvents.data.result.filter(event => event.competition === resCompetition.data.result.uuid))
      const resEntries = await axios(API_URLS.ENTRIES);
      const _entries = resEntries.data.result.filter(e => e.leader === _athlete.PK || e.partner === _athlete.PK);
      setEntries(_entries);
    }
  }
  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  async function saveAll() {
    const registerDatas = events.map((event, i) => {
      const uuid = document.getElementById("uuid" + i).value;
      const leader = document.getElementById("leader" + i).value;
      const partner = document.getElementById("partner" + i).value;
      const data = {
        event: event.uuid,
        leader: leader,
        partner: partner
      };
      return {uuid: uuid, data: data};
    }).filter(registerData => !IsEmpty(registerData.data));

    if (registerDatas.every(registerData => IsValid(athlete, registerData.data))) {
      try {
        for(var i = 0; i < registerDatas.length; i++) {
          const registerData = registerDatas[i];
          await Register(
            user.signInUserSession.getIdToken().getJwtToken(),
            registerData.uuid,
            registerData.data
          );
        };
        navigate('/entry/event/done/' + params.competitionid);
      } catch (error) {
        if (error.response.data.code === '27') {
          alert("リーダーとパートナーは必須です。");
        } else if (error.response.data.code === '28') {
          alert("リーダーとパートナーが同じです。");
        } else if (error.response.data.code === '29' || error.response.data.code === '30') {
          alert("このユーザーは同じ種目に既に登録されています。");
        } else if (error.response.data.code === '31') {
          alert("無効なエントリーです。");
        } else {
          alert("競技エントリーに失敗しました。");
        }
      }
    } else {
      alert("入力内容を確認してください。");
    }
  }

  const isEntryOnTime = useMemo(() => {
    if (competition) {
      const now = new Date().setHours(0, 0, 0);
      const entryDate = new Date(competition.entry_date);
      return now < entryDate;
    }
  }, [competition]);

  const selects = events.map((event, i) => {
    const _entry = entries.find(e => e["GSI2-ENTRY-EVENT"] === event.uuid);
    const entryPK = _entry !== undefined ? _entry.PK : "";
    const entryEventPK = _entry !== undefined ? _entry["GSI2-ENTRY-EVENT"] : "";
    return <EntrySelect
             isEntryOnTime={isEntryOnTime}
             event={event}
             key={i}
             eventKey={i}
             entryPK={entryPK}
             entryEventPK={entryEventPK}
             leader={getLeader(athlete, _entry)}
             partner={getPartner(athlete, _entry)}
             leaders={getLeaders(athlete, event, leaders)}
             partners={getPartners(athlete, event, partners)}
             athlete={athlete}
             formIsDirty={formIsDirty}
             setFormIsDirty={setFormIsDirty}
           />;
  });

  if (competition) {
    return (
      <div className="App">
        <div className="main">
          <div className="title comp-title">競技エントリー</div>
          <ul className="mb30 fz13">
            {selects}
          </ul>
        </div>
        {new Date().getTime() < new Date(competition.entry_date).getTime() && (
          <div className="bulk_btn">
            {formIsDirty ? (
              <Button onClick={async () => await saveAll()} className="btn" variation="primary">
                一括エントリー
              </Button>
            ) : (
              <Button className="btn" variation="primary" disabled>一括エントリー</Button>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return(
    <Error />
    );  
  }
}
