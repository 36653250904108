import { useState, useEffect } from "react"; //Reactを読み込んでいる
import axios from "axios";
import { useParams } from 'react-router-dom';

import { API_URLS } from '../Constants';
import { EntryTable } from '../components/EntryTable';
import { Error } from './Error';
import { Link } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';

function convertDanceName2Key(name) {
  const DANCE_KEY = {
    W: "ワルツ",
    T: "タンゴ",
    V: "べニーズワルツ",
    F: "スローフォックストロット",
    Q: "クイック",
    S: "サンバ",
    C: "チャチャチャ",
    R: "ルンバ",
    P: "パソドブレ",
    J: "ジャイブ",
    Jb: "ジルバ",
    Br: "ブルース",
    Sl: "サルサ",
  };

  const result = Object.keys(DANCE_KEY).find(key => DANCE_KEY[key] === name);
  return result !== undefined ? result : "";
}

function generateTableDatas(athletes, events, entries, me) {
  let objs = [];
  const eventNames = [...new Set(events.map(event => event.name))];
  console.log(eventNames);
  eventNames.forEach((eventName, i) => {
    let obj = {
      name: eventName,
      events: [],
      rowDatas: [],
      entryCounts: [],
      dreamLeaders: [],
      dreamLeaderEntryCounts: [],
      dreamPartners: [],
      dreamPartnerEntryCounts: [],
    };
    const _events = events.filter(
      event => event.name === eventName
    ).map(
      event => {
        event.dance_type = convertDanceName2Key(event.dance_type);
        return event;
      }
    );
    obj.events = _events;

    const eventUUIDs = _events.map(event => event.uuid);
    const _entries = entries.filter(entry => eventUUIDs.indexOf(entry["GSI2-ENTRY-EVENT"]) !== -1);

    const isDream = _events.some(event => event.is_dream === true)
    if (isDream !== true) {
      const athleteUUIDs = [...new Set(_entries.map(entry => entry.leader))];
      athleteUUIDs.forEach((athleteUUID, j) => {
        const _athlete = athletes.find(athlete => athlete.uuid === athleteUUID);
        if (_athlete === undefined) { return; }
        let rowData = {leader: _athlete, className: "", entries: []};
        if (me) if (me !== undefined && Object.keys(me).indexOf("PK") && _athlete.PK === me.PK) rowData.className = "is-mine";
        eventUUIDs.forEach((eventUUID, k) => {
          const _entry = _entries.find(e => e["GSI2-ENTRY-EVENT"] === eventUUID && e.leader === athleteUUID);
          if (_entry !== undefined) {
            let entry = _entry;
            const partner = athletes.find(ath => ath.uuid === entry.partner);
            if (partner === undefined) { return; }
            if (me) if (me !== undefined && Object.keys(me).indexOf("PK") && partner.PK === me.PK) rowData.className = "is-mine";
            entry.partner = partner;
            rowData.entries.push(entry);
          } else {
            rowData.entries.push(undefined);
          }
        });

        if (rowData.entries.filter(e => e !== undefined).length > 0) {
          obj.rowDatas.push(rowData);
        }
      });
    } else {
      const athleteUUIDs = [...new Set(_entries.map(entry => {
        return entry.leader !== "抽選" ? entry.leader : entry.partner
      }))];
      console.log(athleteUUIDs);
      athleteUUIDs.forEach((athleteUUID, j) => {
        const athlete = athletes.find(_athlete => _athlete.uuid === athleteUUID)
        if (athlete === undefined) { return; }
        let rowDataLeader = {athlete: athlete, className: "", entries: []};
        let rowDataPartner = {athlete: athlete, className: "", entries: []};
        eventUUIDs.forEach((eventUUID, k) => {
          const leaderEntry = _entries.find(e => e["GSI2-ENTRY-EVENT"] === eventUUID && e.leader === athleteUUID)
          const partnerEntry = _entries.find(e => e["GSI2-ENTRY-EVENT"] === eventUUID && e.partner === athleteUUID)
          if (leaderEntry !== undefined) {
            if (me) if (leaderEntry.leader === me.PK) rowDataLeader.className = "is-mine";
            rowDataLeader.entries.push(leaderEntry);
          } else {
            rowDataLeader.entries.push(undefined);
          }
          if (partnerEntry !== undefined) {
            if (me) if (me !== undefined && Object.keys(me).indexOf("PK") && partnerEntry.partner === me.PK) rowDataPartner.className = "is-mine";
            rowDataPartner.entries.push(partnerEntry);
          } else {
            rowDataPartner.entries.push(undefined);
          }
        });
        if (rowDataLeader.entries.filter(e => e !== undefined).length > 0) {
          obj.dreamLeaders.push(rowDataLeader);
        }
        if (rowDataPartner.entries.filter(e => e !== undefined).length > 0) {
          obj.dreamPartners.push(rowDataPartner);
        }
      });
    }

    eventUUIDs.forEach((eventUUID, j) => {
      const _rowDatas = obj.rowDatas.filter(rowData => rowData.entries.filter(entry => entry && entry["GSI2-ENTRY-EVENT"] === eventUUID).length > 0);
      obj.entryCounts.push(_rowDatas.length);
      const _dreamLeaderDatas = obj.dreamLeaders.filter(rowData => rowData.entries.filter(entry => entry && entry["GSI2-ENTRY-EVENT"] === eventUUID).length > 0);
      obj.dreamLeaderEntryCounts.push(_dreamLeaderDatas.length);
      const _dreamPartnerDatas = obj.dreamPartners.filter(rowData => rowData.entries.filter(entry => entry && entry["GSI2-ENTRY-EVENT"] === eventUUID).length > 0);
      obj.dreamPartnerEntryCounts.push(_dreamPartnerDatas.length);
    });

    objs.push(obj);
  });

  return objs;
}

const EntryTables = (props) => {
  const [ components, setComponents ] = useState([]);
  useEffect(() => {
    setComponents(props.objs.map((obj, i) => {
      return <EntryTable obj={obj} index={i} key={i} />
    }));
  }, [props.objs]);

  return (
    <>
      {components}
    </>
  );
}

export function Status() {
  const [ competition, setCompetition ] = useState([]);
  const [ entryDatas, setEntryDatas ] = useState([]); 
  const { user } = useAuthenticator((context) => [context.user]);
  const params = useParams();

  const fetchData = async () => {
    const resCompetition = await axios(API_URLS.COMPETITIONS + "/" + params.competitionid);
    if (resCompetition.data.result.status !== "公開") {
      return;
    }
    setCompetition(resCompetition.data.result);

    const resAthletes = await axios(API_URLS.ATHLETES);
    let _athlete = undefined
    if (user) {
      _athlete = resAthletes.data.result.find(ath => ath.user === user.attributes.sub && ath.competition === params.competitionid);
    }
    const resEvents = await axios(API_URLS.EVENTS);
    const resEntries = await axios(API_URLS.ENTRIES);

    const events = resEvents.data.result.filter(event => event.competition === resCompetition.data.result.uuid);
    const tableDatas = generateTableDatas(resAthletes.data.result, events, resEntries.data.result, _athlete);
    setEntryDatas(tableDatas);
  }

  useEffect(() => {
    if (entryDatas.length === 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (competition) {
    return (
      <div className="App">
        <div id="main" className="main">
          <h1 className="mb30">エントリー状況</h1>
          <div className="mb30">ダンスの種類については<Link to={"/qa"} className="link">こちら</Link>をご確認ください。</div>
          <EntryTables objs={entryDatas} />
        </div>
      </div>
    );
  } else {
    return(
    <Error />
    );  
  }
}
