import { RequireAuth } from './Auths';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Login } from './pages/Login';
import { Search } from './pages/Search';
import { Mypage } from './pages/Mypage';
import { ContractMypage } from './pages/ContractMypage';
import { UserUpdate } from './pages/userUpdate';
import { VerificationCode } from './pages/VerificationCode';
import { ChangePassword } from './pages/ChangePassword';
import { CompetitionUpdate } from './pages/CompetitionUpdate'
import { Create } from './pages/Create'
import { Status } from './pages/Status'
import { Detail } from './pages/Detail';
import { ContractDetail } from './pages/ContractDetail';
import { ContractEvent } from './pages/ContractEvent';
import { Entry } from './pages/Entry';
import { Qa } from './pages/Qa.js';
import { Contact } from './pages/Contact';
import { Privacy } from './pages/Privacy';
import { Unsubscribe } from './pages/Unsubscribe';
import { Done } from './pages/Done.js';
import { CompetitionEntry } from './pages/CompetitionEntry';
import { ScrollToTop } from './components/ScrollToTop';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Menu } from './components/Menu';
import { Error } from './pages/Error';

import './App.css';

export function DanceSportRoutes() {
  return (
    <BrowserRouter>
      <div className="wrapper">
        <ScrollToTop>
          <Header />
          <Menu />
          <Routes>
            <Route path="/" element={<Search />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/mypage"
              element={
                <RequireAuth>
                  <Mypage />
                </RequireAuth>
              }
            />
            <Route
              path="/change/password"
              element={
                <RequireAuth>
                  <ChangePassword />
                </RequireAuth>
              }
            />
            <Route
              path="/change/password/done"
              element={
                <RequireAuth>
                  <Done type="change_password" />
                </RequireAuth>
              }
            />
            <Route
              path="/contract/mypage"
              element={
                <RequireAuth host>
                  <ContractMypage />
                </RequireAuth>
              }
            />
            <Route
              path="/update/user"
              element={
                <RequireAuth>
                  <UserUpdate />
                </RequireAuth>
              }
            />
            <Route
              path="/verification/code"
              element={
                <RequireAuth>
                  <VerificationCode />
                </RequireAuth>
              }
            />
            <Route
              path="/update/user/done"
              element={
                <RequireAuth>
                  <Done type="update_user" />
                </RequireAuth>
              }
            />
            <Route
              path="/update/competition/:competitionid"
              element={
                <RequireAuth host>
                  <CompetitionUpdate />
                </RequireAuth>
              }
            />
            <Route
              path="/update/competition/done/:competitionid"
              element={
                <RequireAuth host>
                  <Done type="edit_competition" regist={true} />
                </RequireAuth>
              }
            />
            <Route
              path="/create/competition"
              element={
                <RequireAuth host>
                  <Create />
                </RequireAuth>
              }
            />
            <Route
              path="/create/competition/done/:competitionid"
              element={
                <RequireAuth host>
                  <Done type="create_competition" regist={true} />
                </RequireAuth>
              }
            />
            <Route
              path="/delete/competition/done"
              element={
                <RequireAuth host>
                  <Done type="delete_competition" />
                </RequireAuth>
              }
            />
            <Route
              path="/undelete/competition/done"
              element={
                <RequireAuth host>
                  <Done type="undelete_competition" />
                </RequireAuth>
              }
            />
            <Route
              path="/detail/:competitionid"
              element={
                <Detail />
              }
            />
            <Route
              path="/contract/detail/:competitionid"
              element={
                <RequireAuth host>
                  <ContractDetail />
                </RequireAuth>
              }
            />
{/*
            <Route
              path="/contract/event/:competitionid"
              element={
                <RequireAuth host>
                  <ContractEvent />
                </RequireAuth>
              }
            />
*/}
            <Route
              path="/contract/event/done/:competitionid"
              element={
                <RequireAuth host>
                  <Done type="edit_event" />
                </RequireAuth>
              }
            />
            <Route
              path="/entry/event/:competitionid"
              element={
                <RequireAuth>
                  <Entry />
                </RequireAuth>
              }
            />
            <Route
              path="/entry/event/done/:competitionid"
              element={
                <RequireAuth>
                  <Done type="entry_event" />
                </RequireAuth>
              }
            />
            <Route
              path="/entry/competition/:competitionid"
              element={
                <RequireAuth>
                  <CompetitionEntry />
                </RequireAuth>
              }
            />
            <Route
              path="/entry/competition/done/:competitionid"
              element={
                <RequireAuth>
                  <Done type="entry" entry={true} />
                </RequireAuth>
              }
            />
            <Route
              path="/cancel/competition/done/:competitionid"
              element={
                <RequireAuth>
                  <Done type="entry_cancel" />
                </RequireAuth>
              }
            />
            <Route path="/competition/status/:competitionid" element={<Status />} />
            <Route path="/qa" element={<Qa />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact/done" element={<Done type="contact" />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/unsubscribe/done" element={<Done type="unsubscribe" />} />
            <Route path="*" element={<Error />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </div>
    </BrowserRouter>
  );
}
