import React from "react";
import { Link, useParams } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button } from '@aws-amplify/ui-react';
import { API_URLS } from '../Constants';
import "../App.css";

export function DoneMessage(props) {
  const DESCRIPTION_TEXT = {
    entry: ["出場登録を承りました。"],
    entry_cancel: ["出場をキャンセルしました。"],
    entry_event: ["競技エントリーを承りました。"],
    contact: ["お問合せを承りました。", "お問合せ内容を確認の上、3営業日以内にお返事いたします。"],
    update_user: ["ユーザ情報を編集しました。"],
    create_competition: ["競技会作成を承りました。"],
    edit_competition: ["競技会変更を承りました。"],
    edit_event: ["競技情報編集を承りました。"],
    delete_competition: ["競技会削除を承りました。"],
    undelete_competition: ["競技会公開を承りました。"],
    change_password: ["パスワードの変更を承りました。"],
    unsubscribe: ["アカウントを削除しました。"]
  }
  const texts = DESCRIPTION_TEXT[props.type];
  return (
    <div className="mb30">
    { texts.map((text, i) => (
      <p key={i}>{text}</p>
    ))}
    </div>
  );
}

const createLinkButton = (props) => {
  return <Button className="btn" variation="primary"><Link to={props.url}>{props.text}</Link></Button>
}

export function Done(props) {
  const params = useParams();
  
  const LINK_PARAMS = {
    entry: [{url: "/entry/event/" + params.competitionid, text: "競技エントリーへ"}, {url: "/detail/" + params.competitionid, text: "競技会詳細へ"}],
    entry_cancel: [{url: "/detail/" + params.competitionid, text: "競技会詳細へ"}],
    entry_event: [{url: "/detail/" + params.competitionid, text: "競技会詳細へ"}],
    contact: [{url: "/mypage", text: "マイページへ"}],
    update_user: [{url: "/mypage", text: "マイページへ"}],
    create_competition: [{url: "/contract/detail/" + params.competitionid, text: "主催者用競技会詳細へ"}],
    edit_competition: [{url: "/contract/detail/" + params.competitionid, text: "主催者用競技会詳細へ"}],
    edit_event: [{url: "/contract/detail/" + params.competitionid, text: "主催者用競技会詳細へ"}, {url: "/contract/mypage", text: "主催者用マイページへ"}],
    delete_competition: [{url: "/contract/mypage", text: "主催者用マイページへ"}],
    undelete_competition: [{url: "/contract/mypage", text: "主催者用マイページへ"}],
    change_password: [{url: "/mypage", text: "マイページへ"}],
    unsubscribe: [{url: "/", text: "競技会検索へ"}]
  }

  const MemoCreateLinkButton = React.memo(createLinkButton);
  const dicts = LINK_PARAMS[props.type];

  const { user } = useAuthenticator((context) => [context.user]);

  return (
    <div className="App">
      <div id="main" className="main">
        <h1 className="title">お知らせ</h1>
        <label
          htmlFor="button-menu-check"
          className="done-check"
        ></label>
        <DoneMessage type={props.type} />
        { dicts.map((dict, i) => (
          <MemoCreateLinkButton url={dict.url} text={dict.text} key={i} />
        ))}
      </div>
    </div>
  );
}
