import React, { useState } from "react"; //Reactを読み込んでいる
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useNavigate, Link } from 'react-router-dom';
import { useAuthenticator, Button } from '@aws-amplify/ui-react';
import { useRecoilState } from 'recoil';
import { data } from "../atom.js"
import { checkContainsParentheses } from "../Utils";

export const UserUpdate = () => {
  const navigate = useNavigate();
  const [ currentEmail, setCurrentEmail ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ familyName, setFamilyName ] = useState("");
  const [ givenName, setGivenName ] = useState("");
  const [ circleName, setCircleName ] = useState("");
  const [ _unusedAtomData, setAtomData ] = useRecoilState(data);
  const { user } = useAuthenticator((context) => [context.user]);
  const getCognitoUser = async () => {
    const result = await Auth.currentAuthenticatedUser({bypassCache: true});
    setCurrentEmail(result.attributes.email);
    setEmail(result.attributes.email);
    setFamilyName(result.attributes.family_name ? result.attributes.family_name : "");
    setGivenName(result.attributes.given_name ? result.attributes.given_name : "");
    setCircleName(result.attributes["custom:circle_name"] ? result.attributes["custom:circle_name"] : "");
  }
  useEffect(() => {
    getCognitoUser();
  }, []);

  const isValid = (field, text) => {
    document.getElementById(field).setCustomValidity(text);
    document.getElementById(field + "-error").innerHTML = text;
  }

  const update = async () => {
    const circleNameHasParentheses = checkContainsParentheses(circleName);

    if (email && familyName && givenName && !circleNameHasParentheses) {
      try {
        isValid("email", "");
        isValid("family_name", "");
        isValid("given_name", "");
        isValid("circle_name", "");
        await Auth.updateUserAttributes(user, {
          // 更新したい情報を渡す
          email: email,
          family_name: familyName,
          given_name: givenName,
          "custom:circle_name": circleName,
        });
        const setData = {
          family_name: familyName,
          circle_name: circleName,
          is_contractor: user.attributes["custom:is_contractor"] !== undefined ? user.attributes["custom:is_contractor"] : "default",
        };
        setAtomData(setData);
        if (email !== currentEmail) {
          // 承認コード入力
          navigate('/verification/code');
        } else {
          navigate('/update/user/done');
        }
      } catch (error) {
        switch (error.code) {
          case 'AliasExistsException':
            alert("メールアドレスが他のユーザーに登録されています。");
            break;
          default:
            alert("更新に失敗しました。");
        }
      }
    } else {
      if (!email) isValid("email", "メールアドレスを入力してください");
      if (!familyName) isValid("family_name", "氏名(姓)を入力してください");
      if (!givenName) isValid("given_name", "氏名(名)を入力してください");
      if (circleNameHasParentheses) isValid("circle_name", "サークル名には括弧を使うことはできません");
    }
  }
  return (
    <div className="App">
      <div id="main" className="main">
          <h1 className="title">ユーザー情報編集</h1>
        <div className="fz13 mb30">
          <div className="mb30">
            <div className="mb10">メールアドレス</div>
            <input id="email" type="email" className="input" value={email} onChange={ function (e) { setEmail(e.target.value.replace(/\s+/g, "")); isValid("email", ""); } }></input>
            <div id="email-error" className="alert"></div>
          </div>
          <div className="mb30 text-align-right">
            <div className="mb10 text-align-left">パスワード</div>
            <Link to="/change/password" className="underbar">パスワードを変更する</Link>
          </div>
          <div className="mb30">
            <div className="mb10">氏名（姓）</div>
            <input id="family_name" className="input" value={familyName} onChange={ function (e) { setFamilyName(e.target.value.replace(/\s+/g, "")); isValid("family_name", ""); } }></input>
            <div id="family_name-error" className="alert"></div>
          </div>
          <div className="mb30">
            <div className="mb10">氏名（名）</div>
            <input id="given_name" className="input" value={givenName} onChange={ function (e) { setGivenName(e.target.value.replace(/\s+/g, "")); isValid("given_name", ""); } }></input>
            <div id="given_name-error" className="alert"></div>
          </div>
          <div className="mb30">
            <div className="mb10">サークル名</div>
            <input id="circle_name" className="input" value={circleName} onChange={ function (e) { setCircleName(e.target.value); } }></input>       
            <div id="circle_name-error" className="alert"></div>
          </div>
        </div>
        <Button onClick={() => {update()}} className="btn" variation="primary">ユーザー情報編集</Button>
      </div>
    </div>
  );
}
