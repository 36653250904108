import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useAuthenticator, Button } from "@aws-amplify/ui-react";
import axios from "axios";
import React from 'react';
import { data } from "../atom.js"
import { useRecoilValue } from 'recoil';
import Modal from 'react-modal';
import { API_URLS } from "../Constants";
import { DateFormat } from "../Utils";
import { Error } from './Error';

export const Detail = () => {
  const navigate = useNavigate();
  const [competition, setCompetition] = useState({});
  const { user } = useAuthenticator((context) => [context.user]);
  const [ athlete, setAthlete ] = useState({});
  const params = useParams();
  const atoms = useRecoilValue(data);

  const fetchData = async () => {
    // You can await here
    const result = await axios(
      API_URLS.COMPETITIONS + "/" + params.competitionid
    );
    setCompetition(result.data.result);
    if (!user) {
      return
    }
    getAthlete(result.data.result);
  }

  const getAthlete = async (_competition) => {
    const response = await axios(API_URLS.ATHLETES);
    response.data.result
      .filter((ath) => ath.user === user.attributes.sub && ath.competition === _competition.PK)
      .forEach((ath) => {
        setAthlete(ath);
      });
  }

  const deleteAthlete = async () => {
    const authrization = {
      Authorization: user.signInUserSession.getIdToken().getJwtToken(),
    };
    if (athlete.uuid) {
      await axios.delete(API_URLS.ATHLETES + "/" + athlete.uuid, {
        headers: authrization,
      });
      setAthlete({});
    }
    navigate('/cancel/competition/done/' + params.competitionid);
  }

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    fetchData();
  }, [user]);

  if (competition) {
    return (
      <div className="App">
        <div className="main">
          <div className="comp-detail">
            <div className="comp-detail-title color">{competition.name}</div>
            <div className="comp-detail-content">
              <div className="flex mb16">
                <label
                  htmlFor="button-menu-check"
                  className="emoji-image comp-detail-date"
                ></label>
                <div>
                  <div className="bold mb10">開催日</div>
                  {DateFormat(new Date(competition.event_date))}
                </div>
              </div>
              <div className="flex mb16">
                <label
                  htmlFor="button-menu-check"
                  className="emoji-image comp-detail-calnder"
                ></label>
                <div>
                  <div className="bold mb10">エントリー期限</div>
                  {DateFormat(new Date(competition.entry_date))}
                </div>
              </div>
              <div className="flex mb16 detail-detail">
                <label
                  htmlFor="button-menu-check"
                  className="emoji-image comp-detail-info"
                ></label>
                <div>
                  <div className="bold mb10">詳細</div>
                  <a
                    href={competition.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {competition.url}
                  </a>
                </div>
              </div>
              <div className="flex mb16">
                <label
                  htmlFor="button-menu-check"
                  className="emoji-image comp-detail-person"
                ></label>
                <div className="nickname">
                  <div className="bold mb10">ニックネーム</div>
                  <div className="nickname-detail">{athlete.nicname}</div>
                </div>
              </div>
              <div className="flex mb16">
                <label
                  htmlFor="button-menu-check"
                  className="emoji-image comp-detail-group"
                ></label>
                <div>
                  <div className="bold mb10">サークル名</div>
                  {athlete.circle_name}
                </div>
              </div>
              <div className="flex mb16">
                <label
                  htmlFor="button-menu-check"
                  className="emoji-image comp-detail-check"
                ></label>
                <div>
                  <div className="bold mb10">登録番号</div>
                  {athlete.registory_number}
                </div>
              </div>
            </div>
          </div>
          <div>
            { (!athlete.uuid && new Date().setHours(0, 0, 0) < new Date(competition.entry_date).getTime()) && (
              <Button className="btn" variation="primary">
                <Link to={"/entry/competition/" + params.competitionid}>
                  出場登録
                </Link>
              </Button>
            )}
            { athlete.uuid && (
            <Button className="btn" variation="primary">
              <Link to={"/entry/competition/" + params.competitionid}>
                出場情報変更
              </Link>
            </Button>
            )}
            { athlete.uuid && (
              <Button className="btn" variation="primary">
                <Link to={"/entry/event/" + params.competitionid}>競技エントリー</Link>
              </Button>
            )}
            <Button className="btn" variation="primary">
              <Link to={"/competition/status/" + params.competitionid}>エントリー状況</Link>
            </Button>
            { athlete.uuid &&
              <Button className="btn" variation="primary" onClick={async () => await openModal()}>
                出場キャンセル
              </Button>
            }
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              ariaHideApp={false}
              style={{content: {height: '180px'}}}
              contentLabel="Cancel Modal"
            >
              <div>
                出場をキャンセルしますか。<br />※競技会の出場キャンセルのため競技エントリーが全て削除されます。
              </div>
              <div className="modal-button">
                <div className="delete-confirm mr30" onClick={closeModal}>いいえ</div>
                <div className="delete-confirm" onClick={async () => await deleteAthlete()}>はい</div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  } else {
    return(
    <Error />
    );  
  }
}
