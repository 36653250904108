import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { CheckboxField, useAuthenticator, Button } from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import axios from "axios";

import { API_URLS } from '../Constants';
import { DateFormat, checkContainsParentheses } from '../Utils';
import { data } from "../atom.js"
import { useRecoilValue } from 'recoil';
import { Error } from './Error';

export function CompetitionEntry() {
  const navigate = useNavigate();
  const [ competition, setCompetition ] = useState({});
  const { user } = useAuthenticator((context) => [context.user]);
  const [ athlete, setAthlete ] = useState({});
  const [ nickName, setNickName ] = useState("");
  const [ circleName, setCircleName ] = useState("");
  const [ leader, setLeader ] = useState(false);
  const [ partner, setPartner ] = useState(false);
  const params = useParams();
  const atoms = useRecoilValue(data);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  async function fetchData() {
    // You can await here
    const result = await axios(API_URLS.COMPETITIONS + "/" + params.competitionid);
    if (result.data.result.status === "公開") {
      setCompetition(result.data.result);
    }
  }

  async function getAthleteData() {
    const resAthletes = await axios(API_URLS.ATHLETES);
    const _athletes = resAthletes.data.result.filter(ath => ath.user === user.attributes.sub && ath.competition === params.competitionid);
    if (_athletes.length > 0) {
      setAthlete(_athletes[0]);
      setNickName(_athletes[0].nicname);
      setLeader(_athletes[0].leader);
      setPartner(_athletes[0].partner);
      setCircleName(_athletes[0].circle_name)
    }
    else{
      if (user.attributes["custom:circle_name"]){
        setCircleName(user.attributes["custom:circle_name"]);
      } else if (atoms.circle_name !== "default"){
        setCircleName(atoms.circle_name);
      }
    }
  }

  useEffect(() => {
    if (Object.keys(competition).length === 0) {
      fetchData();
    }
    if (user) {
      getAthleteData();
      if(atoms.family_name==="default"){
        if (!user.attributes.given_name || !user.attributes.family_name) {
          openModal();
        }
      }
    }
  }, [user]);

  function isValid(field, text) {
    document.getElementById(field).setCustomValidity(text);
    document.getElementById(field + "-error").innerHTML = text;
  }

  async function register() {
    const authrization = {
      Authorization: user.signInUserSession.getIdToken().getJwtToken(),
    };
    const data = {
      competition: competition.uuid,
      nicname: nickName,
      leader: leader,
      partner: partner,
      circle_name: circleName
    };

    const nicnameHasParentheses = checkContainsParentheses(data.nicname);
    const circleNameHasParentheses = checkContainsParentheses(data.circle_name);

    if (data.nicname && !nicnameHasParentheses &&
       (data.leader || data.partner) &&
       data.circle_name && !circleNameHasParentheses) {
      try {
        isValid("nicname", "");
        isValid("leader", "");
        isValid("circle_name", "");
        if (athlete.uuid !== undefined) {
          await axios.put(
            API_URLS.ATHLETES + "/" + athlete.uuid,
            data,
            {headers: authrization}
          )
        } else {
          await axios.post(
            API_URLS.ATHLETES,
            data,
            {headers: authrization}
          );
        }
        navigate('/entry/competition/done/' + competition.uuid);
        return
      } catch (error) {
        alert("競技会への出場登録に失敗しました。");
      }
    } else {
      if (!data.nicname) isValid("nicname", "ニックネームを入力してください");
      if (data.nicname && nicnameHasParentheses) {
        isValid("nicname", "ニックネームには括弧は使えません");
      }
      if (!data.leader && !data.partner) isValid("leader", "チェックを入れてください");
      if (!data.circle_name) isValid("circle_name", "サークル名を入力してください");
      if (data.circle_name && circleNameHasParentheses) {
        isValid("circle_name", "サークル名には括弧は使えません");
      }
    }
  }
  if (competition) {
    return (
      <div className="App">
        <div className="main">
          <div className="title comp-title">出場登録</div>
          <div className="comp-detail comp-detail-short">
            <div className="comp-detail-title color">{competition.name}</div>
            <div className="comp-detail-content">
              <div className="flex mb16">
                <label
                  htmlFor="button-menu-check"
                  className="emoji-image comp-detail-date"
                ></label>
                <div>
                  <div className="bold mb10">開催日</div>
                  {DateFormat(new Date(competition.event_date))}
                </div>
              </div>
              <div className="flex mb16">
                <label
                  htmlFor="button-menu-check"
                  className="emoji-image comp-detail-calnder"
                ></label>
                <div>
                  <div className="bold mb10">エントリー期限</div>
                  {DateFormat(new Date(competition.entry_date))}
                </div>
              </div>
              <div className="flex mb16 detail-detail">
                <label
                  htmlFor="button-menu-check"
                  className="emoji-image comp-detail-info"
                ></label>
                <div>
                  <div className="bold mb10">詳細</div>
                  <a
                    href={competition.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {competition.url}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="comp-input">
            <div className="comp-label"><label htmlFor="nicname">ニックネーム</label></div>
            <input id="nicname" className="input" value={nickName} onChange={ function (e) { setNickName(e.target.value); isValid("nicname", ""); } }></input>
            <div id="nicname-error" className="alert"></div>
          </div>
          <div className="comp-input">
            <div className="comp-checkbox">
              {Object.keys(athlete).length === 0 ? (
              <div><CheckboxField id="leader" name="leader" value="1" label="リーダー" checked={leader} onChange={ function (e) { setLeader(e.target.checked); isValid("leader", ""); } }/></div>
              ) : (
              <div className="gray_check"><CheckboxField id="leader" name="leader" value="1" label="リーダー" checked={leader}/></div>
              )}
              {Object.keys(athlete).length === 0 ? (
              <div><CheckboxField id="partner" name="partner" value="1" label="パートナー" checked={partner} onChange={ function (e) { setPartner(e.target.checked); isValid("leader", ""); } }  /></div>
              ) : ( 
              <div className="gray_check"><CheckboxField id="partner" name="partner" value="1" label="パートナー" checked={partner}/></div>
              )}
            </div>
            <div id="leader-error" className="alert mb10"></div>
            <div>※リーダー・パートナーは初回のみ設定可能で変更不可になります。</div>
          </div>
          <div className="comp-input">
            <div className="comp-label"><label htmlFor="nicname">サークル名</label></div>
            <input id="circle_name" className="input" value={circleName} onChange={ function (e) { setCircleName(e.target.value); isValid("circle_name", ""); } }></input>
            <div id="circle_name-error" className="alert"></div>
          </div>
          <div>
            <Button onClick={async () => await register()} className="btn" variation="primary">出場登録</Button>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          ariaHideApp={false}
          style={{content: {height: '10em'}}}
          contentLabel="UserUpdate Modal"
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
        >先に マイページ の ユーザー情報編集 から 氏名(姓) と 氏名(名) を登録してください。
          <div className="modal-button">
            <div className="pointer"><Link to={"/update/user/"}>ユーザ情報更新へ移動</Link></div>
          </div>
        </Modal>
      </div>
    );
  } else {
    return(
    <Error />
    );  
  }
}
