import React from "react";
import "../App.css";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

export function Qa() {
  return (
    <div className="App">
      <div id="main" className="main">
        <h1 className="title">Q&A</h1>
        <div className="mb30">お問い合わせの多い内容を掲載しております。該当するご質問内容を選択し、ご確認ください。</div>
        
        <Accordion allowZeroExpanded className="accordion__box mb30">
        <AccordionItem>
          <AccordionItemHeading className="bold">
            <AccordionItemButton>Q アカウント登録時の認証メールが届かない</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="flex accordion-answer"><div className="bold mr10">A</div> キャリア（docomo、au、softbankなど）のセキュリティ設定で、以下のドメインを受信できるように設定してください。<br /> 「@dancesport.kandaforge.com」</AccordionItemPanel>
        </AccordionItem>
      </Accordion>

      <Accordion allowZeroExpanded className="accordion__box mb30">
        <AccordionItem>
          <AccordionItemHeading className="bold">
            <AccordionItemButton>Q エントリー状況画面のアルファベットは何を意味してますか？</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="flex accordion-answer"><div className="bold mr10">A</div> 
          W: ワルツ<br /> 
          T: タンゴ<br /> 
          V: べニーズワルツ<br /> 
          F: スローフォックストロット<br /> 
          Q: クイック<br /> 
          S: サンバ<br /> 
          C: チャチャチャ<br /> 
          R: ルンバ<br /> 
          P: パソドブレ<br /> 
          J: ジャイブ<br /> 
          Jb: ジルバ<br /> 
          Br: ブルース<br /> 
          Sl: サルサ<br /> 
          です。
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>

      </div>
    </div>
  );
}
