import { createContext, useState } from 'react';

export const MenuContext = createContext();

export const MenuProvider = (props) => {
  const [menuState, setMenuState] = useState(false);
  return (
    <MenuContext.Provider value={{menuState, setMenuState}}>
      {props.children}
    </MenuContext.Provider>
  );
}
