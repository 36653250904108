// RequireAuth.js
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

export function RequireAuth({ children, host }) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  const { user } = useAuthenticator((context) => [context.user]);

  if (route === "authenticated") {
    if (host === true) {
      if ((user !== undefined) && user.hasOwnProperty("attributes")) {
        const attributes = user["attributes"];
        console.log(user);
        if (attributes.hasOwnProperty("custom:is_contractor") && attributes["custom:is_contractor"] === "1") {
          return children;
        } else {
          return <Navigate to="/mypage" replace />;
        }
      }
    } else {
      return children;
    }
  } else if (route === "idle") {
    return;
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
}
