import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useNavigate, useLocation } from "react-router";
import { useRecoilState } from "recoil";
import { data } from "../atom.js"
import { useRecoilValue } from 'recoil';

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const [ atoms, setAtomData ] = useRecoilState(data);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";

  const services = {
    async handleSignIn(formData) {
      const { username, password } = formData;
      return Auth.signIn({
        username,
        password      
      }).then((user) => {
        let family_name = user.attributes.family_name !== undefined ? user.attributes.family_name : "default";
        let circle_name = user.attributes["custom:circle_name"] ? user.attributes["custom:circle_name"] : "default";
        let _isContractor = user.attributes["custom:is_contractor"] !== undefined ? user.attributes["custom:is_contractor"] : "-1";

        setAtomData({
          family_name: family_name,
          circle_name: circle_name,
          is_contractor: _isContractor,
        });
        return user;
      });
    },
    async validateCustomSignUp(formData) {
      if (formData.password && formData.password.length < 6){
        return {
          password: 'パスワードは最低6文字入力してください',
        };
      }
    },
  };

  useEffect(() => {
    if (route === "authenticated" && atoms.is_contractor !== "default") {
      if (atoms.is_contractor === "1") {
        from = "/contract/mypage"
      }
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <View className="auth-wrapper">
      <Authenticator services={services}></Authenticator>
    </View>
  );
}
