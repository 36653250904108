import React, { useState, useCallback, useEffect/*, useMemo*/ } from "react"; //Reactを読み込んでいる
import axios from "axios";
//import DatePicker, { registerLocale } from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
//import ja from "date-fns/locale/ja";

import { API_URLS } from "../Constants";
import { CompetitionUl } from "../components/Competition";

export function SearchMain(props) {
  console.log("SearchMain ready");
  //registerLocale("ja", ja);
  //const [ filterObj, setFilterObj ] = useState({keyword: "", startDate: null, endDate: null});
  //const [ isSearch, setIsSearch ] = useState(false);
  ////カレンダー表示
  //const handleDateChange = (date) => {
  //  const [start, end] = date;
  //  setFilterObj({
  //    keyword: filterObj.keyword,
  //    startDate: start,
  //    endDate: end,
  //  });
  //};
  //const handleKeywordChange = (value) => {
  //  setFilterObj({
  //    keyword: value,
  //    startDate: filterObj.startDate,
  //    endDate: filterObj.endDate,
  //  });
  //};
  //const clear = () => {
  //  setFilterObj({
  //    keyword: "",
  //    startDate: null,
  //    endDate: null,
  //  });
  //  setIsSearch(!isSearch);
  //}
  //const search = () => {
  //  setIsSearch(!isSearch);
  //}

  //const filteredCompetitions = useMemo(() => {
  //  const searchKeywords = filterObj.keyword.toLowerCase().trim().match(/[^\s]+/g);
  //  console.log("searchKeywords");
  //  return props.competitions.filter((competition) => {
  //    // TODO: 正しいlocal or utc時間として統一されているかは怪しい
  //    const eventDate = new Date(competition.event_date.split("T")[0] + "T00:00:00"); // 日付のみでnew Date
  //    if (searchKeywords && searchKeywords.length > 0 && filterObj.startDate && filterObj.endDate) {
  //      return (
  //        searchKeywords.every((kw) => competition.name.toLowerCase().indexOf(kw) !== -1) &&
  //        filterObj.startDate <= eventDate &&
  //        eventDate <= filterObj.endDate
  //      );
  //    } else if (searchKeywords && searchKeywords.length > 0) {
  //      return searchKeywords.every((kw) => competition.name.toLowerCase().indexOf(kw) !== -1);
  //      
  //    } else if (filterObj.startDate && filterObj.endDate) {
  //      return filterObj.startDate <= eventDate && eventDate <= filterObj.endDate;
  //    } else {
  //      return true;
  //    }
  //  })
  //}, [props.competitions, isSearch]);

  return (
    <div id="main" className="main">
      <h2 className="title">競技会検索</h2>
{/*
      <div className="fz13 mb30 main-box">
        <div className="pd30">
          <div className="mb30">
            <div className="mb10">競技会名</div>
            <input
              type="text"
              placeholder=""
              className="search-name"
              onChange={(e) => handleKeywordChange(e.target.value)}
              onClear={() => handleKeywordChange("")}
              value={filterObj.keyword}
            />
          </div>
          <div className="mb30">
            開催日
            <br />
            <DatePicker
              dateFormat="yyyy/MM/dd"
              onChange={handleDateChange}
              className="calendar search_calendar"
              locale="ja"
              startDate={filterObj.startDate}
              endDate={filterObj.endDate}
              selectsRange
            />
          </div>
          <div className="float-right">
            <button className="btn clear-btn" onClick={clear}>
              クリア
            </button>
            <button className="btn short-btn" onClick={search}>
              検索
            </button>
          </div>
        </div>
      </div>
      <div className="bold">検索結果</div>
      <CompetitionUl objs={filteredCompetitions} isContractor={false} />
*/}

      <CompetitionUl objs={props.competitions} isContractor={false} />
    </div>
  );
}

export function Search() {
  console.log("Search ready");
  const [ competitions, setCompetitions ] = useState([]);
  const MemoSearch = React.memo(props => {
    console.log("render Child");
    return <SearchMain competitions={props.competitions} />;
  });

  const updateCompetitions = useCallback(async () => {
    console.log("call useCallback");
    const result = await axios(API_URLS.COMPETITIONS);
    const tmp = result.data.result.filter(e => e.status === "公開");
    let competitions = [];
    if (result.data.result.length > 1) {
      competitions = [tmp.at(-1)];
    } else {
      competitions = tmp;
    }
    setCompetitions(competitions);
  }, []);

  useEffect(() => {
    if (competitions.length === 0) {
      console.log("call updateCompetitions");
      updateCompetitions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <MemoSearch competitions={competitions} />
    </div>
  );
}
