import awsconfig from "./aws-exports";

export const BASE_URL = awsconfig.aws_cloud_logic_custom[0].endpoint + "/";
export const API_URLS = {
  COMPETITIONS: BASE_URL + "competitions",
  CONTRACT_COMPETITIONS: BASE_URL + "contractcompetitions",
  EVENTS: BASE_URL + "events",
  ATHLETES: BASE_URL + "athletes",
  ENTRIES: BASE_URL + "entries",
  DOWNLOAD_ENTRIES: awsconfig.aws_cloud_logic_custom[1].endpoint + "/download/entries",
  VERIFY_ATTRIBUTE: BASE_URL + "/verifyattribute",
  UPLOAD: awsconfig.aws_cloud_logic_custom[1].endpoint + "/upload",
  UNSUBSCRIBE: BASE_URL + "unsubscribe"
}
export const DATEFORMAT = "yyyy/MM/dd (E)";
