import React from "react";

import { useLongAnswerInput } from "react-google-forms-hooks";

export default function LongAnswerInput(props) {
  const { register, error } = useLongAnswerInput(props.id);

  return (
    <div>
      <textarea className={error ? "input contact-input alert" : "input contact-input"} type="text" {...register()} />
      <div className="alert">{error && props.field + "を入力してください"}</div>
    </div>
  );
}
