import React, { useState } from "react"; //Reactを読み込んでいる
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticator, Button } from "@aws-amplify/ui-react";
import { useParams } from "react-router-dom";
import { API_URLS } from "../Constants";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";

import axios from "axios";
import { Error } from './Error';

export function CompetitionUpdate() {
  registerLocale("ja", ja);
  const navigate = useNavigate();
  const [competition, setCompetition] = useState({});
  const [title, setTitle] = useState("");
  const [entryDate, setEntryDate] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [url, setUrl] = useState("");
  //const [result, SetResult] = useState("");
  const { user } = useAuthenticator((context) => [context.user]);
  const params = useParams();
  async function fetchData() {
    const _competition = await axios(API_URLS.COMPETITIONS + "/" + params.competitionid);
    setCompetition(_competition.data.result);

    const entryDateBefore = Date.parse(_competition.data.result.entry_date);
    const entryDate = new Date(entryDateBefore);
    const eventDateBefore = Date.parse(_competition.data.result.event_date);
    const eventDate = new Date(eventDateBefore);
    if (_competition.data.result.user_sub === user.attributes.sub) {
      setTitle(_competition.data.result.name);
      setEntryDate(entryDate);
      setEventDate(eventDate);
      setUrl(_competition.data.result.url);
    }
    //SetResult(result);
  }
  const handleEntryDateInput = (date) => {
    // setEntryDate(date)
    setEntryDate(date);
    isValid("entryDate", "")
  };
  const handleEventDateInput = (date) => {
    setEventDate(date);
    // setEventDate(date.toISOString());
    isValid("eventDate", "")
  };
  async function isValid(field, text) {
    document.getElementById(field).setCustomValidity(text);
    document.getElementById(field + "-error").innerHTML = text;
  }
  useEffect(() => {
    fetchData();
  }, []);

  async function Update() {
    if (title.trim() && entryDate && eventDate && url.trim()) {
      try {
        isValid("title", "");
        isValid("entryDate", "");
        isValid("eventDate", "");
        isValid("url", "");
        if (url.trim().match(/^https?:\/\/[\w/:%#\$&\?\(\)~\.=\+\-]+$/) === null) {
          throw "詳細には http:// あるいは https:// ではじまるURLを入力してください";
        }
        const authrization = {
          Authorization: user.signInUserSession.getIdToken().getJwtToken(),
        };
        const data = {
          name: title,
          url: url,
          entry_date: entryDate.toISOString(),
          event_date: eventDate.toISOString(),
          is_final_open: false,
          //status: "公開",
        };
        if (competition.user_sub === user.attributes.sub) {
          await axios.put(
            API_URLS.COMPETITIONS + "/" + params.competitionid,
            data,
            {
              headers: authrization,
            }
          );
          navigate("/update/competition/done/" + params.competitionid);
        } else {
          const resCompetition = await axios.post(API_URLS.COMPETITIONS, data, {
            headers: authrization,
          });
          navigate("/create/competition/done/" + resCompetition.data.uuid);
        }
      } catch (error) {
        {/* See amplify/backend/function/dancesportLambda/src/core/error.py */}
        if (typeof(error) === "string") {
          if(error.indexOf("URL") !== -1) alert(error);
        } else if (error.response.data.code === '21') {
          alert("競技会名が空です。");
        } else if (error.response.data.code === '22' || error.response.data.code === '23') {
          alert("エントリー期限か開催日が現在日よりも前に設定されています。");
        } else if (error.response.data.code === '24') {
          alert("エントリー期限よりも開催日が前に設定されています。");
        } else {
          alert("競技会作成に失敗しました。");
        }
      }
    } else {
      if (!(title.trim())) isValid("title", "競技会名を入力してください");
      if (!eventDate) isValid("eventDate", "開催日を入力してください");
      if (!entryDate) isValid("entryDate", "エントリー期限を入力してください");
      if (!(url.trim())) isValid("url", "詳細を入力してください");
    }
  }
  if (competition) {
    return (
      <div className="App">
        <div id="main" className="main">
          <h1 className="title">競技会編集</h1>
          <div className="fz13 mb30">
            <div className="mb30">
              必要事項を入力して競技会を変更してください。
            </div>
            <div className="mb30">
              <div className="mb10">競技会名<div className="mandatory">必須</div></div>
              <input
                id="title"
                className="input"
                value={title}
                onChange={ function (e) { setTitle(e.target.value); isValid("title", ""); }}
              ></input>
               <div id="title-error" className="alert"></div>
            </div>
            <div className="mb30">
              <div className="mb10">開催日<div className="mandatory">必須</div></div>
              <DatePicker
                id="eventDate"
                selected={eventDate}
                dateFormat="yyyy/MM/dd"
                onChange={handleEventDateInput}
                className="calendar create_calendar"
                locale="ja"
              />
              <div id="eventDate-error" className="alert"></div>
            </div>
            <div className="mb30">
              <div className="mb10">エントリー期限<div className="mandatory">必須</div></div>
              <DatePicker
              id="entryDate"
                selected={entryDate}
                dateFormat="yyyy/MM/dd"
                onChange={handleEntryDateInput}
                className="calendar create_calendar"
                locale="ja"
              />
              <div id="entryDate-error" className="alert"></div>
            </div>
            <div className="mb30">
              <div className="mb10">詳細（ホームページなどのURL）<div className="mandatory">必須</div></div>
              <input
                id="url"
                value={url}
                className="input"
                onChange={ function (e) { setUrl(e.target.value); isValid("url", ""); }}
                ></input>
              <div id="url-error" className="alert"></div>
            </div>
          </div>
          <Button onClick={async () => await Update()} className="btn" variation="primary">
            変更
          </Button>
        </div>
      </div>
    );
  } else {
    return(
    <Error />
    );  
  }
}
