import React from "react";
import { useContext } from 'react';
import { MenuContext } from '../Providers'
import { useNavigate, useLocation } from 'react-router-dom';

export function Header(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { setMenuState } = useContext(MenuContext);

  const openMenu = (flag) => {
    setMenuState(flag);
  }

  return (
    <header>
      <div className="header-content">
        {location.pathname.indexOf("login") === -1 &&
        location.pathname.indexOf("done") === -1 &&
          <label
            htmlFor="button-back-check"
            className="button-back"
            onClick={() => navigate(-1)}
          ></label>
        }
        <div className="header-title">競技会エントリーシステム</div>
        <label
          htmlFor="button-menu-check"
          className="button-menu"
          onClick={() => openMenu(true)}
        ></label>
      </div>
    </header>
  )
}
