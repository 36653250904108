import React from "react";
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer>
      <div className="footer">
        <div className="footer-inner">
          <ul className="footer-list">
            <li className="mb16">
              <Link to="/qa">Q&amp;A</Link>
            </li>
            <div>|</div>
            <li className="mb16">
              <Link to="/privacy">プライバシーポリシー</Link>
            </li>
            <div>|</div>
            <li className="mb16">
              <Link to="/contact">お問い合わせ</Link>
            </li>
          </ul>
          <div className="copyright">© 競技会エントリーシステム</div>
        </div>
      </div>
    </footer>
  );
}
