import { useState, useEffect, useCallback } from "react"; //Reactを読み込んでいる
import { useNavigate } from "react-router-dom"; //　追加　Linkタブを読み込む
import { useAuthenticator, Button, Loader } from "@aws-amplify/ui-react";
import { useRecoilValue } from 'recoil';
import { data } from "../atom.js"
import axios from "axios";
import Modal from 'react-modal';

import { API_URLS } from "../Constants";
import { CompetitionUl } from "../components/Competition";

const uploadModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    height: '150px',
    minWidth: '200px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 100,
  }
};

Modal.setAppElement('#root');

export function ContractMypage() {
  console.log("Mypage render");
  const { user } = useAuthenticator((context) => [context.user]);
  const [ competitions, setCompetitions ] = useState([])
  const atoms = useRecoilValue(data);
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedFile, setselectedFile] = useState(null);
  const [uploadEnabled, setUploadEnabled] = useState(false);
  const [cancelEnabled, setCancelEnabled] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const getData = useCallback(async () => {
    console.log("call useCallback");
    const jwtToken = user.signInUserSession.getIdToken().getJwtToken();
    const authrization = {Authorization: jwtToken};
    const resCompetitions = await axios(API_URLS.CONTRACT_COMPETITIONS, {headers: authrization});
    setCompetitions(
      resCompetitions.data.result.filter(
        (com) => com.user_sub === user.attributes.sub
      )
    );
  }, [user]);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const family_name = () => {
    if (atoms.family_name !== "default" && atoms.family_name !== undefined) {
      return atoms.family_name;
    } else if (!user.attributes.family_name) {
      return user.attributes.email;
    } else if (!user) {
      return "";
    }
    return user.attributes.family_name;
  }

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    if (showLoader === false) {
      setUploadEnabled(false);
      setCancelEnabled(true);
      setselectedFile(null);
      setIsOpen(false);
  
    }
  }

  const startUpload = async (e) => {
    // UI状態の変更
    setUploadEnabled(false);
    setCancelEnabled(false);
    setShowLoader(true);

    // ファイルアップロード
    const formData = new FormData();
    formData.append("data_upload", selectedFile);
    await axios.post(API_URLS.UPLOAD, formData, {
      headers: {
        Authorization: user.signInUserSession.getIdToken().getJwtToken(),
        'Content-Type': 'multipart/form-data'
      }
    })

    // 競技会一覧を再度取得、表示しなおす
    getData();

    // UI状態を復帰する
    setselectedFile(null);
    setIsOpen(false);
    setCancelEnabled(true);
    setShowLoader(false);
  }

  return (
    <div className="App">
      <div className="color welcome">
        <div className="welcome-text">
          <div className="mb10 fz18">ようこそ</div>
          <div className="flex">
            <div className="fz18">{family_name()}</div>
            <div className="fz14 san">さん</div>
          </div>
        </div>
        <div className="welcome-contract">主催者</div>
      </div>
      <div id="main" className="pt100 main">
        <div className="mypage-button">
          <Button className="btn" variation="primary" onClick={() => navigate("/update/user")}>
            ユーザー情報編集
          </Button>
        </div>
        <div className="mypage-button">
          <Button className="btn" variation="primary" onClick={() => navigate("/create/competition")}>
            競技会作成
          </Button>
          <Button className="btn" variation="primary" onClick={() => {
              openModal();
            }}>
            競技会一括登録
          </Button>
        </div>
        <div className="mt30">競技会一覧</div>
        <CompetitionUl objs={competitions} isContractor={1} />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={uploadModalStyles}
        contentLabel="File Upload Modal"
        parentSelector={() => document.querySelector('.App')}
      >
        <h2 className="mb10">{showLoader === true ? "アップロード中..." : "競技会情報のExcelファイルを選択してください"}</h2>
        <div className="mb10">
          {showLoader === true ? <div style={{"padding": "5px", "marginBottom": "5px"}}>
            <Loader variation="linear" />
            </div> :
          <input type="file" onChange={(event) => {
            const fileTarget = event.target;
            if (fileTarget.files.length > 0) {
              setselectedFile(fileTarget.files[0]);
              setUploadEnabled(true);
            } else {
              setselectedFile(null);
              setUploadEnabled(false);
            }
          }} />}
        </div>
        <div className="modal-button">
          {cancelEnabled === true ? <div className="delete-confirm mr30" onClick={closeModal}>キャンセル</div> : <div style={{"color": "gray", "padding": "5px", "fontWeight": "bold"}}>キャンセル</div>}
          {uploadEnabled === true ?
            <div className="delete-confirm" onClick={async () => {await startUpload()}}>登録する</div> :
            <div style={{"color": "gray", "padding": "5px", "fontWeight": "bold"}}>登録する</div>}
        </div>
      </Modal>
    </div>
  );
}
