import Amplify from "aws-amplify";
import { Authenticator } from '@aws-amplify/ui-react';
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import { RecoilRoot } from 'recoil';

import "./App.css";
import { DanceSportRoutes } from './Routers'
import { MenuProvider } from './Providers';

Amplify.configure(awsconfig);

translations['ja']['Username'] = 'ユーザー名（重要）';
translations['ja']['User does not exist.'] = 'ユーザーが存在しません。';
translations['ja']['Incorrect username or password.'] = 'ユーザー名かパスワードが間違っています。';
translations['ja']['Your passwords must match'] = 'パスワードが一致しません';
translations['ja']['Invalid verification code provided, please try again.'] = 'コードが間違っています。もう一度やり直してください。';
translations['ja']['Password must have at least 6 characters'] = 'パスワードは最低6文字入力してください';
translations['ja']['We Emailed You'] = 'メールを送信しました';
//translations['ja']['Your code is on the way. To log in, enter the code we emailed to '] = 'ログインに必要なコードを次のメールアドレスに送付しました。';
translations['ja']['It may take a minute to arrive.'] = '数分以内に入力したメールアドレス宛にコードが届きます。';
translations['ja']['Enter your code'] = 'コードを入力して下さい';
translations['ja']['Sign In'] = 'ログイン';
translations['ja']['Sign in'] = 'ログイン';
I18n.putVocabularies(translations);
I18n.setLanguage('ja');


export function App() {
  return (
    <RecoilRoot>
    <Authenticator.Provider>
      <MenuProvider>
        <DanceSportRoutes />
      </MenuProvider>
    </Authenticator.Provider>
    </RecoilRoot>
  );
}
