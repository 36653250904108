import React, { useState } from "react";
import { Button, CheckboxField, useAuthenticator } from '@aws-amplify/ui-react';
import "../App.css";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { API_URLS } from '../Constants';


export async function postUnsubscribe(jwtToken, data) {
    const authrization = {Authorization: jwtToken};
    const response = await axios.post(
        API_URLS.UNSUBSCRIBE,
        data,
        {headers: authrization}
      );
      return response
}

export function Unsubscribe() {
    const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);
    const navigate = useNavigate();
    const [ checked, setChecked ] = useState(false);
    const unsubscribe = async () => {
        if (user) {
            try {
                await postUnsubscribe(
                    user.signInUserSession.getIdToken().getJwtToken(),
                    {"accessToken" : user.signInUserSession.getAccessToken().getJwtToken()}
                )
                signOut();
                navigate('/unsubscribe/done', { replace: true });
            } catch {
                alert("一度画面を更新してください。");
            }
        } else {
            alert("既にログアウト済みです。");
        }
    }
        
    function changeBoolean() {
        if (checked === false) {
            setChecked(true)
        }
        if (checked === true) {
            setChecked(false)
        }
    }    
    return (
        <div className="App">
            <div id="main" className="main">
                <h1 className="title">アカウントを削除しますか？</h1>
                <label>
                <div className="flex">
                    <CheckboxField id="check" className="mr10" onClick={changeBoolean}/>
                    アカウントが削除されることを理解しました。
                </div>
                </label>
                { checked ? (
                    <Button onClick={() => unsubscribe()} className="btn" variation="primary" id="unsubscribe">アカウント削除</Button>
                    ) : (
                    <Button className="btn disabled-btn" variation="primary" id="unsubscribe" disabled>アカウント削除</Button>

                )}
                <Button onClick={() => navigate(-1)} className="btn" variation="primary">キャンセル</Button>
            </div>
        </div>
    );
}