import React from "react";
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
//import { useAuthenticator, IconClose } from '@aws-amplify/ui-react';
import { useAuthenticator, Icon } from '@aws-amplify/ui-react';
import { MenuContext } from '../Providers'

export function Menu() {
  const { user, route, signOut } = useAuthenticator((context) => [context.user, context.route, context.signOut]);
  const navigate = useNavigate();
  const { menuState, setMenuState } = useContext(MenuContext);
  const showMenu = "menu menu-bg show-menu";
  const closeMenu = "menu menu-bg";
  function logout() {
    signOut();
    navigate("/login");
  }

  return (
    <>
      <div className={menuState === true ? showMenu : closeMenu} onClick={() => setMenuState(false)}>
        <div className="content">
          <ul className="mb30 fz13">
            <li className="tb-border-menu">
              {/*<IconClose ariaLabel="Close" onClick={() => setMenuState(false)} />*/}
              <Icon ariaLabel="Close" onClick={() => setMenuState(false)} />
            </li>
            <li className="tb-border-menu">
              <Link to="/" onClick={() => setMenuState(false)} className="menu-link">
                <div>競技会検索</div>
              </Link>
            </li>
            { route === 'authenticated' &&
              <li className="tb-border-menu">
                <Link to="/mypage" onClick={() => setMenuState(false)} className="menu-link">
                  <div>マイページ</div>
                </Link>
              </li>
            }
            { user !== undefined && user.hasOwnProperty("attributes") && user.attributes["custom:is_contractor"] &&
              <li className="tb-border-menu">
                <Link to="/contract/mypage" onClick={() => setMenuState(false)} className="menu-link">
                  <div>主催者用マイページ</div>
                </Link>
              </li>
            }
            { route === 'authenticated' ? (
              <li className="tb-border-menu menu-link" onClick={logout}>
                <div className="logout">ログアウト</div>
              </li>
            ) : (
              <li className="tb-border-menu">
                <Link to="/login" onClick={() => setMenuState(false)} className="menu-link">ログイン</Link>
              </li>
            )}
            { route === 'authenticated' &&
               <li className="tb-border-menu">
                 <Link to="/unsubscribe" onClick={() => setMenuState(false)} className="menu-link">
                   <div>退会</div>
                 </Link>
               </li>
              }
          </ul>
        </div>
      </div>
    </>
  );
}
