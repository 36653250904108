import React from "react";
import { Link } from "react-router-dom";
import "../App.css";

export function Privacy() {
  //const markdown = `
  //- お客さまの同意がある場合
  //- お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
  //- 法令に基づき開示することが必要である場合
  //`
  // const html = marked(markdown);
  return (
    <div className="App">
      <div id="main" className="main">
      <h1 className="title">プライバシーポリシー</h1>
      <div className="mb30">このウェブサイトは、ダンス競技会のエントリーサイトです。</div>
      <li className="mb30">
        <h2>個人情報保護方針</h2>
        <div>当社は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。</div>
      </li>
      <li className="mb30">
        <h2>個人情報の管理</h2>
        <div>当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行います。</div>
      </li>
      <li className="mb30">
        <h2>個人情報の利用目的</h2>
        <div>本ウェブサイトでは、お客様からのお問い合わせ時または採用、就業および退職等に必要となる手続等を実施するために、お名前、e-mailアドレス、電話番号等の個人情報をご登録いただく場合がございますが、これらの個人情報はご提供いただく際の目的以外では利用いたしません。</div>
        <br/>
        <div>お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。</div>
      </li>
      <li className="mb30">
        <h2>個人情報の第三者への開示・提供の禁止</h2>
        <div>当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。</div>
        <br/>
        <div>・お客さまの同意がある場合</div>
        <div>・お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合</div>
        <div>・法令に基づき開示することが必要である場合</div>
      </li>
      <li className="mb30">
        <h2>個人情報の安全対策</h2>
        <div>当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。</div>
      </li>
      <li className="mb30">
        <h2>個人情報の管理</h2>
        <div>当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行います。</div>
      </li>
      <li className="mb30">
        <h2>ご本人の照会</h2>
        <div>お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。</div>
      </li>
      <li className="mb30">
        <h2>法令、規範の遵守と見直し</h2>
        <div>当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。</div>
      </li>
      <li className="mb30">
        <h2>お問い合わせ</h2>
        <div>当社の個人情報の取扱に関するお問い合わせは下記までご連絡ください</div>
        <br/>
        <div>株式会社ウニークス</div>
        <div>101-0047</div>
        <div>東京都千代田区内神田1丁目9番10号 キムラビル4階</div>
        <Link to={"/contact/"} rel="noopener noreferrer" className="underbar">お問い合わせはこちら</Link>
      </li>
      </div>
    </div>
  );
}
