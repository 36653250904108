import { useState, useEffect, useMemo } from 'react';
import { useAuthenticator, Button } from '@aws-amplify/ui-react';
import { IsEmpty, IsValid, Register, Cancel } from '../pages/Entry';

export function SelectField(props) {
  return (
    <>
      <label htmlFor={props.id}>{props.label}</label>
      { props.isDisabled ? (
      <select
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={
          (e) => {
            props.setValue(e.target.value);
            props.setFormIsDirty(true);
          }
        } disabled>
        <option value="">----</option>
        {props.options.map((option, i) => (
        <option key={i} value={option.PK}>
          {option.PK !== "抽選" ? option.registory_number + ". " : ""}{option.nicname}
        </option>
        ))}
      </select>
      ) : (
      <select
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={
          (e) => {
            props.setValue(e.target.value);
            props.setFormIsDirty(true);
          }
        }>
        <option value="">----</option>
        {props.options.map((option, i) => (
        <option key={i} value={option.PK}>
          {option.PK !== "抽選" ? option.registory_number + ". " : ""}{option.nicname}
        </option>
        ))}
      </select>
      )}
    </>
  );
};

export const EntrySelect = (props) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [ leader, setLeader ] = useState("");
  const [ partner, setPartner ] = useState("");
  const [ entryEventPK, setEntryEventPK ] = useState("");
  const [ uuid, setUuid ] = useState("");
  const [ viewEntry, setViewEntry ] = useState(false);
  const [ viewCancel, setViewCancel ] = useState(false);
  const [ canceled, setCanceled ] = useState(false);

  useEffect(() => {
    setLeader(props.leader);
    setPartner(props.partner);
    setEntryEventPK(props.entryEventPK);
    setUuid(props.entryPK);
  }, [props.entryPK]);

  const checkEntry = useMemo(() => {
    if (entryEventPK === props.event.PK) {
      return true;
    }
    return false;
  }, [entryEventPK, props.event]);

  async function save(eventId, formNo) {
    const data = {
      event: props.event.PK,
      leader: leader,
      partner: partner
    };
    try {
      if (!IsEmpty(data) && IsValid(props.athlete, data)) {
        const response = await Register(
          user.signInUserSession.getIdToken().getJwtToken(),
          uuid,
          data
        );
        setViewEntry(true);
        setViewCancel(false);
        setCanceled(false);
        setEntryEventPK(response.data.result["GSI2-ENTRY-EVENT"]);
        setUuid(response.data.result.PK)
      } else {
        alert("入力内容を確認してください。");
      }
    } catch (error) {
      if (error.response.data.code === '27') {
        alert("リーダーとパートナーは必須です。");
      } else if (error.response.data.code === '28') {
        alert("リーダーとパートナーが同じです。");
      } else if (error.response.data.code === '29' || error.response.data.code === '30') {
        alert("このユーザーは同じ種目に既に登録されています。");
      } else if (error.response.data.code === '31') {
        alert("無効なエントリーです。");
      } else {
        alert("一度画面を更新してください。");
      }
    }
  }
  async function cancel(eventId, formNo) {
    try {
      await Cancel(
        user.signInUserSession.getIdToken().getJwtToken(),
        uuid,
      );
      setViewEntry(false);
      setViewCancel(true);
      setCanceled(true);
      if (props.athlete.leader && !props.athlete.partner) {
        setLeader(props.athlete.PK);
      } else {
        setLeader("");
      }
      if (!props.athlete.leader && props.athlete.partner) {
        setPartner(props.athlete.PK);
      } else {
        setPartner("");
      }
      setEntryEventPK("");
      setUuid("");
    } catch (error) {
      console.log(error);
      alert("エントリーキャンセルに失敗しました。一度画面更新をしてください。");
    }
  }

  return (
    <li className="entry-block">
      <div className="comp-title">{props.event.name}&nbsp;&nbsp;{props.event.dance_type}</div>
      <input type="hidden" name='uuid' id={"uuid" + props.eventKey} value={uuid}></input>
      <div className="entry-select">
        <SelectField
          id={"leader" + props.eventKey}
          label="リーダー"
          name="leader"
          value={leader}
          setValue={setLeader}
          options={props.leaders}
          setFormIsDirty={props.setFormIsDirty}
          isDisabled={(checkEntry && canceled === false) || !props.isEntryOnTime || (props.athlete.leader && !props.athlete.partner)}
        />
      </div>
      <div className="entry-select">
        <SelectField
          id={"partner" + props.eventKey}
          label="パートナー"
          name="partner"
          value={partner}
          setValue={setPartner}
          options={props.partners}
          setFormIsDirty={props.setFormIsDirty}
          isDisabled={(checkEntry && canceled === false) || !props.isEntryOnTime || (!props.athlete.leader && props.athlete.partner)}
        />
      </div>
      {viewEntry === true &&
        <div className='done-entry mb16 bold hideMe'>エントリーしました</div>
      }
      {viewCancel === true &&
        <div className='done-entry mb16 bold hideMe white'>キャンセルしました</div>
      }
      { props.isEntryOnTime ? (
        checkEntry && canceled === false ? (
          <Button className="btn disabled-btn" variation="primary" disabled>エントリー</Button>
        ) : (
          <Button onClick={async () => await save(props.event.uuid, props.eventKey)} className="btn" variation="primary">エントリー</Button>
        )
      ) : (
        <button className="hide-btn" disabled>エントリー</button>
      )}
      {checkEntry && canceled === false &&
        <Button onClick={async () => await cancel(props.event.uuid, props.eventKey)} className="btn cancel-btn mt16">キャンセル</Button>
      }
    </li>
  );
}
