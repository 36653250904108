import React, { useState } from "react"; //Reactを読み込んでいる
import { useNavigate } from 'react-router-dom';
import { useAuthenticator, Button } from '@aws-amplify/ui-react';
import { API_URLS } from '../Constants';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";

import axios from "axios";

export function Create() {
  registerLocale("ja", ja);
  const navigate = useNavigate();
  const [ title, setTitle ] = useState("");
  const initialDate = new Date()
  const [ entryDate, setEntryDate ] = useState(initialDate);
  const [ eventDate, setEventDate ] = useState(initialDate);
  const [ url, setUrl ] = useState("");
  const { user } = useAuthenticator((context) => [context.user]);
  const handleEntryDateInput = (date) => {
      setEntryDate(date);
      isValid("entryDate", "")
  };
  const handleEventDateInput = (date) => {
      setEventDate(date);
      isValid("eventDate", "")
  };
  async function isValid(field, text) {
    document.getElementById(field).setCustomValidity(text);
    document.getElementById(field + "-error").innerHTML = text;
  }
  async function Create() {
    const urlString = url.trim();
    if (title.trim() && entryDate && eventDate && (urlString !== "")) {
      entryDate.setHours(9, 0, 0)
      eventDate.setHours(9, 0, 0)
      try {
        isValid("title", "");
        isValid("entryDate", "");
        isValid("eventDate", "");
        isValid("url", "");
        if (urlString.startsWith('http://') === false && urlString.startsWith('https://') === false) {
          throw "詳細には http:// あるいは https:// ではじまるURLを入力してください";
        }
        const authrization = {
          Authorization: user.signInUserSession.getIdToken().getJwtToken(),
        };
        const data = {
          name: title,
          url: urlString,
          entry_date: entryDate,
          event_date: eventDate,
          "GSI1-PARENT": "competition"      
        };
        const resCompetition = await axios.post(
          API_URLS.COMPETITIONS,
          data,
          {headers: authrization}
        )
        navigate('/create/competition/done/' + resCompetition.data.result.uuid);
      } catch (error) {
        {/* See amplify/backend/function/dancesportLambda/src/core/error.py */}
        if (typeof(error) === "string") {
          if(error.indexOf("URL") !== -1) alert(error);
        } else if (error.response.data.code === '21') {
          alert("競技会名が空です。");
        } else if (error.response.data.code === '22' || error.response.data.code === '23') {
          alert("エントリー期限か開催日が現在日よりも前に設定されています。");
        } else if (error.response.data.code === '24') {
          alert("エントリー期限よりも開催日が前に設定されています。");
        } else {
          alert("競技会作成に失敗しました。");
        }
      }
    } else {
      if (!(title.trim())) isValid("title", "競技会名を入力してください");
      if (!entryDate) isValid("entryDate", "エントリー期限を入力してください");
      if (!eventDate) isValid("eventDate", "開催日を入力してください");
      if (urlString === "") isValid("url", "詳細を入力してください");
    }

  }
  return (
    <div className="App">
      <div id="main" className="main">
          <h1 className="title">競技会作成</h1>
        <div className="fz13 mb30">
        <div className="mb30">必要事項を入力して競技会を作成してください。</div>
          <div className="mb30">
            <div className="mb10">競技会名<div className="mandatory">必須</div></div>
            <input id="title" className="input" onChange={ function (e) { setTitle(e.target.value); isValid("title", ""); }}></input>
            <div id="title-error" className="alert"></div>
          </div>
          <div className="mb30">
            <div className="mb10">開催日<div className="mandatory">必須</div></div>
            <DatePicker
            id="eventDate"
            selected={eventDate}
            dateFormat="yyyy/MM/dd"
            onChange={handleEventDateInput}
            className="calendar create_calendar"
            locale="ja"
          />
          <div id="eventDate-error" className="alert"></div>
          </div>
          <div className="mb30">
            <div className="mb10">エントリー期限<div className="mandatory">必須</div></div>
            <DatePicker
            id="entryDate"
            selected={entryDate}
            dateFormat="yyyy/MM/dd"
            onChange={handleEntryDateInput}
            className="calendar create_calendar"
            locale="ja"
            />
            <div id="entryDate-error" className="alert"></div>
          </div>
          <div className="mb30">
            <div className="mb10">詳細（ホームページなどのURL）<div className="mandatory">必須</div></div>
            <input id="url" className="input" onChange={ function (e) { setUrl(e.target.value); isValid("url", ""); }}></input>   
            <div id="url-error" className="alert"></div>
          </div>
        </div>
        <Button onClick={async () => await Create()} className="btn" variation="primary">登録</Button>
      </div>
    </div>
  );
}
