import React, { useState } from "react"; //Reactを読み込んでいる

import { useShortAnswerInput } from "react-google-forms-hooks";

export default function ShortAnswerInput(props) {
  const { register, error } = useShortAnswerInput(props.id);
  const [ email, setEmail ] = useState(props.email);
  return (
    <div>
      {props.field === "メールアドレス" ? (
      <input className={error ? "input alert" : "input"} type="email" {...register()} value={email} onChange={function (e){setEmail(e.target.value)}}/>
       ) : (
      <input className={error ? "input alert" : "input"} type="text" {...register()}/>
        )}
      <div className="alert">{error && props.field + "を入力してください"}</div>
    </div>
  );
}
