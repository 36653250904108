import { useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";

// https://stackoverflow.com/questions/70193712/how-to-scroll-to-top-on-route-change-with-react-router-dom-v6

export function ScrollToTop({ children }) {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: "instant"
      });
  }, [location.pathname]);
  return children;
}
