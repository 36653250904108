import { Table, TableHead, TableRow, TableBody, TableCell } from '@aws-amplify/ui-react';

const TABLE_NAME = "EntryTable";
const TABLEHEAD_NAME = "TableHead";
const TABLEBODY_NAME = "TableBody";
const TABLEROW_NAME = "TableRow";
const TABLECELL_NAME = "TableCell";

const DreamTable = (props) => {
  const title = props.type === "leader" ? "リーダー" : "パートナー";
  return (
    <>
      <p className="entry-table-type">{title}</p>
      <div className='entry-table-block'>
        <Table size="small" variation="bordered" key={props.key}>
          <TableHead>
            <TableRow key={props.headRowKey + "-" + props.type + "-0"}>
              <TableCell key={props.headRowKey + "-" + TABLECELL_NAME + "-" + props.type + "-0"}></TableCell>
              {props.events.map((event, i) => (
                <TableCell key={props.headRowKey + "-" + TABLECELL_NAME + "-" + props.type + "-" + (i + 1).toString()}>{event.dance_type}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rowDatas.map((rowData, i) => (
            <TableRow key={props.bodyRowKey + "-" + props.type + "-" + (i + 1).toString() + "-0"} className={rowData.className}>
              <TableCell key={props.bodyRowKey + "-" + props.type + "-" + (i + 1).toString() + "-0"}>{rowData.athlete.registory_number}</TableCell>
              {rowData.entries.map((entry, j) => (
              <TableCell key={props.bodyRowKey + "-" + props.type + "-" + (i + 1).toString() + "-" + (j + 1).toString()}>{entry ? "●" : ""}</TableCell>
              ))}
            </TableRow>
            ))}
            <TableRow>
              <TableCell key={props.bodyRowKey + "-" + props.type + "-" + (props.rowDatas.length + 1).toString() + "-0"}>エントリー数</TableCell>
              {props.entryCounts.map((total, i) => (
              <TableCell key={props.bodyRowKey + "-" + props.type + "-" + (props.rowDatas.length + 1).toString() + "-" + i.toString()}>{total}</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
}

const BasicTable = (props) => {
  return (
    <>
      <div className='entry-table-block'>
        <Table size="small" variation="bordered" key="1">
          <TableHead>
            <TableRow key={props.headRowKey}>
              <TableCell key={props.headRowKey + "-" + TABLECELL_NAME + "-0"}></TableCell>
              {props.events.map((event, i) => (
                <TableCell key={props.headRowKey + "-" + TABLECELL_NAME + "-" + (i + 1).toString()}>{event.dance_type}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rowDatas.map((rowData, i) => (
            <TableRow key={props.bodyRowKey + "-" + (i + 1).toString()} className={rowData.className}>
              <TableCell key={props.bodyRowKey + "-" + (i + 1).toString() + "-0"}>{rowData.leader.registory_number}</TableCell>
              {rowData.entries.map((entry, j) => (
              <TableCell key={props.bodyRowKey + "-" + (i + 1).toString() + "-" + (j + 1).toString()}>{entry ? entry.partner.registory_number : ""}</TableCell>
              ))}
            </TableRow>
            ))}
            <TableRow key={props.bodyRowKey + "-" + (props.rowDatas.length + 1).toString()}>
              <TableCell key={props.bodyRowKey + "-" + (props.rowDatas.length + 1).toString() + "-0"}>エントリー数</TableCell>
              {props.entryCounts.map((total, i) => (
              <TableCell key={props.bodyRowKey + "-" + (props.rowDatas.length + 1).toString() + "-" + (i + 1).toString()}>{total}</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
}

export function EntryTable(props) {
  const TABLE_NAME_KEY = TABLE_NAME + "-" + props.index.toString();
  const TABLEHEAD_ROW_KEY = TABLE_NAME_KEY + "-" + TABLEHEAD_NAME + "-" + TABLEROW_NAME + "-1";
  const TABLEBODY_ROW_KEY = TABLE_NAME_KEY + "-" + TABLEBODY_NAME + "-" + TABLEROW_NAME;

  return (
    <div className='mb30'>
      <p className='entry-table-event-name'>{props.obj.name}</p>
      {props.obj.events.some(e => e.is_dream === true) === false ? (
        <BasicTable
          headRowKey={TABLEHEAD_ROW_KEY}
          bodyRowKey={TABLEBODY_ROW_KEY}
          events={props.obj.events}
          rowDatas={props.obj.rowDatas}
          entryCounts={props.obj.entryCounts}
        />
      ) : (
        <>
          <DreamTable
            headRowKey={TABLEHEAD_ROW_KEY}
            bodyRowKey={TABLEBODY_ROW_KEY}
            type="leader"
            events={props.obj.events}
            rowDatas={props.obj.dreamLeaders}
            entryCounts={props.obj.dreamLeaderEntryCounts}
          />
          <DreamTable
            headRowKey={TABLEHEAD_ROW_KEY}
            bodyRowKey={TABLEBODY_ROW_KEY}
            type="partner"
            events={props.obj.events}
            rowDatas={props.obj.dreamPartners}
            entryCounts={props.obj.dreamPartnerEntryCounts}
          />
        </>
      )}
    </div>
  );
}
