import { atom } from 'recoil';


export const data = atom({
  key: 'data',
  default: {
    family_name: "default",
    circle_name: "default",
    is_contractor: "default",
  },
});
